// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";

function MakeEncodable(M) {
  var encode = M.encode;
  var encode$1 = Curry.__1(encode);
  var decode = M.decode;
  var decode$1 = Curry.__1(decode);
  return {
          encode: encode$1,
          decode: decode$1
        };
}

export {
  MakeEncodable ,
}
/* No side effect */
