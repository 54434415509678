// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Core from "@octokit/core";

function list(token) {
  return new Core.Octokit({
                  auth: token
                }).request("GET /gists", {}).then(function (result) {
              return Belt_Array.map(result.data, (function (x) {
                            return {
                                    id: x.id,
                                    name: Object.keys(x.files)[0],
                                    updated_at: new Date(x.updated_at)
                                  };
                          }));
            });
}

function write(token, id, data, minify) {
  return new Core.Octokit({
                auth: token
              }).request("PATCH /gists/" + id, {
              gist_id: id,
              files: {
                "rist-schach-data.json": {
                  content: minify ? JSON.stringify(data) : JSON.stringify(data, null, 2)
                }
              }
            });
}

function read(token, id) {
  var octokit = new Core.Octokit({
        auth: token
      });
  return octokit.request("GET /gists/" + id, {
                gist_id: id
              }).then(function (x) {
              return Js_dict.values(x.data.files)[0].content;
            });
}

function create(token, data, minify) {
  return new Core.Octokit({
                auth: token
              }).request("POST /gists", {
              files: {
                "rist-schach-data.json": {
                  content: minify ? JSON.stringify(data) : JSON.stringify(data, null, 2)
                }
              }
            });
}

var Gist = {
  list: list,
  write: write,
  read: read,
  create: create
};

export {
  Gist ,
}
/* @octokit/core Not a pure module */
