// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Belt_Float from "rescript/lib/es6/belt_Float.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Utils$RistSchach from "./Utils.bs.js";

function Pages$Splash(Props) {
  return React.createElement("div", {
              className: "pages__container"
            }, React.createElement("aside", {
                  className: "pages__hint"
                }), React.createElement("div", {
                  className: "pages__title"
                }, React.createElement("div", {
                      className: "pages__title-icon"
                    }, React.createElement("img", {
                          alt: "",
                          height: "96",
                          src: Utils$RistSchach.WebpackAssets.logo,
                          width: "96"
                        })), React.createElement("div", {
                      className: "pages__title-text"
                    }, React.createElement("h1", {
                          className: "title",
                          style: {
                            fontSize: "40px"
                          }
                        }, "RistSchach"))), React.createElement("footer", {
                  className: "pages__footer body-20"
                }, React.createElement("div", {
                      className: "pages__footer-left"
                    }), React.createElement("div", {
                      className: "pages__footer-right"
                    }, React.createElement("p", undefined, React.createElement("a", {
                              className: "pages__footer-link",
                              href: Utils$RistSchach.github_url
                            }, "Quellcode"), " ist unter der ", React.createElement("a", {
                              className: "pages__footer-link",
                              href: Utils$RistSchach.license_url
                            }, "Mozilla Public License 2.0"), " verfügbar."))));
}

var Splash = {
  make: Pages$Splash
};

function log2(num) {
  return Math.log(num) / Math.log(2.0);
}

function fixNumber(num) {
  if (num < 0.0 || num === Pervasives.infinity || num === Pervasives.neg_infinity) {
    return 0.0;
  } else {
    return num;
  }
}

function updateInt(dispatch, minimum, $$event) {
  $$event.preventDefault();
  var value = Belt_Option.getWithDefault(Belt_Int.fromString($$event.currentTarget.value), minimum);
  var safeValue = value < minimum ? minimum : value;
  return Curry._1(dispatch, (function (param) {
                return safeValue;
              }));
}

var title = "Rundenrechner";

function Pages$TimeCalculator(Props) {
  var match = React.useState(function () {
        return 2;
      });
  var setPlayers = match[1];
  var players = match[0];
  var match$1 = React.useState(function () {
        return 5;
      });
  var setBreakTime = match$1[1];
  var breakTime = match$1[0];
  var match$2 = React.useState(function () {
        return 4.0;
      });
  var setTotalTime = match$2[1];
  var totalTime = match$2[0];
  return React.createElement("div", {
              className: "content-area"
            }, React.createElement("h1", undefined, title), React.createElement("p", {
                  className: "caption-30"
                }, "Bestimme optimale Rundenanzahl und Zeit pro Runde für dein Schachturnier."), React.createElement("form", undefined, React.createElement("table", {
                      style: {
                        margin: "0"
                      }
                    }, React.createElement("tbody", undefined, React.createElement("tr", undefined, React.createElement("td", undefined, React.createElement("label", {
                                      htmlFor: "playerCount"
                                    }, "Spieleranzahl ")), React.createElement("td", undefined, React.createElement("input", {
                                      id: "playerCount",
                                      style: {
                                        width: "40px"
                                      },
                                      min: String(0),
                                      type: "number",
                                      value: String(players),
                                      onChange: (function (param) {
                                          return updateInt(setPlayers, 0, param);
                                        })
                                    }))), React.createElement("tr", undefined, React.createElement("td", undefined, React.createElement("label", {
                                      htmlFor: "breakTime"
                                    }, "Pausen zwischen Runden ")), React.createElement("td", undefined, React.createElement("input", {
                                      id: "breakTime",
                                      style: {
                                        width: "40px"
                                      },
                                      min: String(0),
                                      step: 5.0,
                                      type: "number",
                                      value: String(breakTime),
                                      onChange: (function (param) {
                                          return updateInt(setBreakTime, 0, param);
                                        })
                                    }), " Minuten")), React.createElement("tr", undefined, React.createElement("td", undefined, React.createElement("label", {
                                      htmlFor: "totalTime"
                                    }, "Gesamtzeit ")), React.createElement("td", undefined, React.createElement("input", {
                                      id: "totalTime",
                                      style: {
                                        width: "40px"
                                      },
                                      min: String(0.5),
                                      step: 0.5,
                                      type: "number",
                                      value: String(totalTime),
                                      onChange: (function (param) {
                                          var minimum = 0.5;
                                          param.preventDefault();
                                          var value = Belt_Option.getWithDefault(Belt_Float.fromString(param.currentTarget.value), minimum);
                                          var safeValue = value < minimum ? minimum : value;
                                          return Curry._1(setTotalTime, (function (param) {
                                                        return safeValue;
                                                      }));
                                        })
                                    }), " Stunden"))))), React.createElement("dl", undefined, React.createElement("dt", {
                      className: "title-20"
                    }, "Optimale Rundenanzahl"), React.createElement("dd", undefined, fixNumber(Math.ceil(log2(players)))), React.createElement("dt", {
                      className: "title-20"
                    }, "Zeit pro Spieler pro Runde"), React.createElement("dd", undefined, React.createElement("span", {
                          className: "title-20"
                        }, fixNumber(Math.ceil((totalTime * 60.0 / Math.ceil(log2(players)) - breakTime) / 2.0)), " Minuten"), React.createElement("span", {
                          className: "caption-20"
                        }, " = ((", React.createElement("strong", {
                              className: "monospace"
                            }, totalTime), " × 60 ÷ ⌈log₂(", React.createElement("strong", {
                              className: "monospace"
                            }, players), ")⌉) - ", React.createElement("strong", {
                              className: "monospace"
                            }, breakTime), ") ÷ 2"))));
}

function Pages$NotFound(Props) {
  return React.createElement("p", {
              className: "content-area"
            }, "Seite nicht gefunden.");
}

var NotFound = {
  make: Pages$NotFound
};

var TimeCalculator = {
  title: title,
  make: Pages$TimeCalculator
};

export {
  Splash ,
  TimeCalculator ,
  NotFound ,
}
/* react Not a pure module */
