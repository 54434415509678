// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactFeather from "react-feather";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as Utils$RistSchach from "./Utils.bs.js";
import VisuallyHidden from "@reach/visually-hidden";

function reducer(param, newState) {
  return newState;
}

function useBool(init) {
  var match = React.useReducer(reducer, init);
  var setState = match[1];
  return {
          state: match[0],
          setTrue: (function (param) {
              setState(true);
            }),
          setFalse: (function (param) {
              setState(false);
            })
        };
}

function sortedTableReducer(state, action) {
  var newState;
  if (typeof action === "number") {
    newState = state;
  } else {
    switch (action.TAG | 0) {
      case /* SetIsDescending */0 :
          newState = {
            isDescending: action._0,
            column: state.column,
            table: state.table
          };
          break;
      case /* SetColumn */1 :
          newState = {
            isDescending: state.isDescending,
            column: action._0,
            table: state.table
          };
          break;
      case /* SetTable */2 :
          newState = {
            isDescending: state.isDescending,
            column: state.column,
            table: action._0
          };
          break;
      
    }
  }
  var direction = newState.isDescending ? Utils$RistSchach.descend : Utils$RistSchach.ascend;
  var f = newState.column;
  var sortFunc;
  switch (f.TAG | 0) {
    case /* GetString */0 :
        var f$1 = f._0;
        sortFunc = Curry._2(direction, Caml.string_compare, (function (str) {
                return f$1(str).toLowerCase();
              }));
        break;
    case /* GetInt */1 :
        sortFunc = Curry._2(direction, Caml.int_compare, f._0);
        break;
    case /* GetFloat */2 :
        sortFunc = Curry._2(direction, Caml.float_compare, f._0);
        break;
    case /* GetDate */3 :
        var f$2 = f._0;
        sortFunc = Curry._2(direction, Caml.float_compare, (function (date) {
                return f$2(date).getTime();
              }));
        break;
    
  }
  var table = Belt_SortArray.stableSortBy(newState.table, sortFunc);
  return {
          isDescending: newState.isDescending,
          column: newState.column,
          table: table
        };
}

function useSortedTable(table, column, isDescending) {
  var initialState = {
    isDescending: isDescending,
    column: column,
    table: table
  };
  var match = React.useReducer(sortedTableReducer, initialState);
  var dispatch = match[1];
  React.useEffect((function () {
          Curry._1(dispatch, /* SortWithoutUpdating */0);
        }), []);
  return [
          match[0],
          dispatch
        ];
}

function Hooks$SortButton(Props) {
  var children = Props.children;
  var sortColumn = Props.sortColumn;
  var data = Props.data;
  var dispatch = Props.dispatch;
  var chevronStyle = data.column === sortColumn ? ({
        opacity: "1"
      }) : ({
        opacity: "0"
      });
  return React.createElement("button", {
              className: "button-micro button-text-ghost title-20",
              style: {
                width: "100%"
              },
              onClick: (function (param) {
                  if (data.column === sortColumn) {
                    return Curry._1(dispatch, {
                                TAG: /* SetIsDescending */0,
                                _0: !data.isDescending
                              });
                  } else {
                    return Curry._1(dispatch, {
                                TAG: /* SetColumn */1,
                                _0: sortColumn
                              });
                  }
                })
            }, React.createElement("span", {
                  "aria-hidden": true
                }, React.createElement(ReactFeather.ChevronUp, {
                      style: {
                        opacity: "0"
                      }
                    })), children, data.isDescending ? React.createElement("span", {
                    style: chevronStyle
                  }, React.createElement(ReactFeather.ChevronUp, {}), React.createElement(VisuallyHidden, {
                        children: "Aufsteigend sortieren."
                      })) : React.createElement("span", {
                    style: chevronStyle
                  }, React.createElement(ReactFeather.ChevronDown, {}), React.createElement(VisuallyHidden, {
                        children: "Absteigend sortieren."
                      })));
}

var SortButton = {
  make: Hooks$SortButton
};

function useLoadingCursorUntil(isLoaded) {
  React.useEffect((function () {
          if (isLoaded) {
            ((document.body.style.cursor = "auto"));
          } else {
            ((document.body.style.cursor = "wait"));
          }
          return (function (param) {
                    return (document.body.style.cursor = "auto");
                  });
        }), [isLoaded]);
}

export {
  useBool ,
  useSortedTable ,
  SortButton ,
  useLoadingCursorUntil ,
}
/* react Not a pure module */
