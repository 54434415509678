// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactFeather from "react-feather";
import * as Router$RistSchach from "./Router.bs.js";
import * as Externals$RistSchach from "./Externals/Externals.bs.js";
import * as Data_Scoring$RistSchach from "./Data/Data_Scoring.bs.js";

function HelpDialogs$BaseDialog(Props) {
  var param = Props.state;
  var ariaLabel = Props.ariaLabel;
  var children = Props.children;
  var setFalse = param.setFalse;
  return React.createElement(Externals$RistSchach.Dialog.make, {
              isOpen: param.state,
              onDismiss: setFalse,
              ariaLabel: ariaLabel,
              children: null,
              className: ""
            }, React.createElement("button", {
                  className: "button-micro",
                  onClick: (function (param) {
                      Curry._1(setFalse, undefined);
                    })
                }, "Fertig"), children);
}

function HelpDialogs$Pairing(Props) {
  var state = Props.state;
  var ariaLabel = Props.ariaLabel;
  return React.createElement(HelpDialogs$BaseDialog, {
              state: state,
              ariaLabel: ariaLabel,
              children: null
            }, React.createElement("p", undefined, "Ein schweizer Turniersystem ist nur effektiv, wenn man die Spieler nach bestimmten Regeln paarweise zusammentut. Diese per Hand zu bestimmen ist möglich, aber das kann der Computer besser."), React.createElement("p", undefined, "Das sind die Regeln:"), React.createElement("ol", undefined, React.createElement("li", undefined, "Zwei Spieler sollten sich nie doppelt begegnen."), React.createElement("li", undefined, "Spieler sollen Gegner mit der gleichen Punktanzahl erhalten."), React.createElement("li", undefined, "Innerhalb einer Punktegruppe sollte die obere Hälfte der Spieler mit der unteren Hälfte zusammengepaart werden."), React.createElement("li", undefined, "Spieler sollten alternierend schwarz und weiß spielen, und niemals eine Farbe drei Mal hintereinander spielen.")), React.createElement("p", undefined, "Die ersten beiden Regeln sind wichtig, damit das schweizer System funktioniert. Regeln 3 und 4 sorgen für fairere Spiele, sind jedoch nicht zwingend notwendig."), React.createElement("p", undefined, "Man kann nie alle Regeln genau befolgen. Der Algorithmus versucht jedoch, so viele wie möglich zu realisieren. Es kann auch sein, dass der Algorithmus eine scheinbar 'offensichtliche' Lösung nicht wählt, weil eine andere im Gesamtbild besser ist."), React.createElement("p", undefined, "Wenn man Spieler manuell gegeneinander paart, wird angezeigt, wie 'ideal' die Paarung ist. Nehme diesen Wert als Vorschlag."));
}

var Pairing = {
  make: HelpDialogs$Pairing
};

function HelpDialogs$SwissTournament(Props) {
  var state = Props.state;
  var ariaLabel = Props.ariaLabel;
  return React.createElement(HelpDialogs$BaseDialog, {
              state: state,
              ariaLabel: ariaLabel,
              children: null
            }, React.createElement("p", undefined, "RistSchach verwendet ", React.createElement("a", {
                      href: "https://de.wikipedia.org/wiki/Schweizer_System"
                    }, "das schweizer System ", React.createElement(ReactFeather.ExternalLink, {})), ". Es werden eine festgelegte Menge an Runden gespielt, wobei die Rundenmenge kleiner als die Spielermenge sein muss. Spieler werden je nach ihrem Punktestand zusammengepaart, aber können sich nie doppelt treffen."), React.createElement("p", undefined, "Es werden verschiedene Tiebreak-Strategien verwendet, um Gleichstand zu klären."), React.createElement("p", undefined, "Die nötige Rundenanzahl kann mit dem ", React.createElement(Router$RistSchach.Link.make, {
                      children: "Rundenrechner",
                      to_: /* TimeCalculator */3
                    }), " bestimmt werden."));
}

var SwissTournament = {
  make: HelpDialogs$SwissTournament
};

function HelpDialogs$TieBreaks(Props) {
  var state = Props.state;
  var ariaLabel = Props.ariaLabel;
  return React.createElement(HelpDialogs$BaseDialog, {
              state: state,
              ariaLabel: ariaLabel,
              children: null
            }, React.createElement("p", undefined, "Ein schweizer System wird immer unentschiedene Ergebnisse hervorbringen. Hier sind verschiedene Tiebreak-Strategien aus dem ", React.createElement("a", {
                      href: "http://www.uschess.org/content/view/7752/369/"
                    }, "USCF Rulebook "), "aufgezählt. Sie können für jedes Turnier deaktiviert oder eine andere Priorität zugewiesen bekommen."), React.createElement("dl", undefined, React.createElement("dt", {
                      className: "title-20"
                    }, "§ 34E1 ", Data_Scoring$RistSchach.TieBreak.toPrettyString(/* Median */0)), React.createElement("dd", undefined, "Summe der Punkte der Gegner, ohne den jeweils höchsten und niedrigsten Wert."), React.createElement("dt", {
                      className: "title-20"
                    }, "§ 34E2 ", Data_Scoring$RistSchach.TieBreak.toPrettyString(/* Solkoff */1)), React.createElement("dd", undefined, "Wie 'modified median', aber mit dem jeweils höchsten und niedrigsten Wert."), React.createElement("dt", {
                      className: "title-20"
                    }, "§ 34E3 ", Data_Scoring$RistSchach.TieBreak.toPrettyString(/* Cumulative */2)), React.createElement("dd", undefined, "Die Summe der Punkte nach jeder Runde für einen bestimmten Spieler. Bevorzugt Spieler, die früher im Turnier gewonnen haben (mit der Annahme, dass sie später stärkere Gegner hatten)."), React.createElement("dt", {
                      className: "title-20"
                    }, "§ 34E9 ", Data_Scoring$RistSchach.TieBreak.toPrettyString(/* CumulativeOfOpposition */3)), React.createElement("dd", undefined, "Summe des 'Cumulative'-Wertes der Gegner eines Spielers."), React.createElement("dt", {
                      className: "title-20"
                    }, "§ 34E6 ", Data_Scoring$RistSchach.TieBreak.toPrettyString(/* MostBlack */4)), React.createElement("dd", undefined, "Die Anzahl der mit schwarz gespielten Spiele - mit weiß gespielten Spiele. Geht davon aus, dass weiß stärker als schwarz ist.")));
}

var TieBreaks = {
  make: HelpDialogs$TieBreaks
};

export {
  Pairing ,
  SwissTournament ,
  TieBreaks ,
}
/* react Not a pure module */
