// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactFeather from "react-feather";
import * as Utils$RistSchach from "../Utils.bs.js";
import * as Router$RistSchach from "../Router.bs.js";
import * as Window$RistSchach from "../Window.bs.js";
import * as Data_Id$RistSchach from "../Data/Data_Id.bs.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as PageRound$RistSchach from "./PageRound.bs.js";
import * as Data_Match$RistSchach from "../Data/Data_Match.bs.js";
import * as Data_Player$RistSchach from "../Data/Data_Player.bs.js";
import * as Data_Rounds$RistSchach from "../Data/Data_Rounds.bs.js";
import * as HtmlEntities$RistSchach from "../HtmlEntities.bs.js";
import * as LoadTournament$RistSchach from "./LoadTournament.bs.js";
import * as PageTourneySetup$RistSchach from "./PageTourneySetup.bs.js";
import * as PageTourneyScores$RistSchach from "./PageTourneyScores.bs.js";
import * as PageTourneyPlayers$RistSchach from "./PageTourneyPlayers.bs.js";
import * as PageTournamentStatus$RistSchach from "./PageTournamentStatus.bs.js";

function PageTourney$Footer(Props) {
  var tournament = Props.tournament;
  var match = tournament.isNewRoundReady ? (
      tournament.isItOver ? [
          HtmlEntities$RistSchach.nbsp + " Alle Runden sind abgeschlossen.  ",
          /* Warning */1
        ] : [
          HtmlEntities$RistSchach.nbsp + " Bereit, eine neue Runde zu beginnen.  ",
          /* Success */0
        ]
    ) : [
      HtmlEntities$RistSchach.nbsp + "Runde läuft.  ",
      /* Generic */3
    ];
  var tooltipText = match[0];
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "win__footer-block"
                }, "Runde ", Data_Rounds$RistSchach.size(tournament.tourney.roundList), React.createElement("small", undefined, " von "), tournament.roundCount), React.createElement("hr", {
                  className: "win__footer-divider"
                }), React.createElement("div", {
                  className: "win__footer-block"
                }, "Spieler: ", Belt_Map.size(tournament.activePlayers)), React.createElement("hr", {
                  className: "win__footer-divider"
                }), React.createElement(Utils$RistSchach.$$Notification.make, {
                  children: tooltipText,
                  kind: match[1],
                  tooltip: tooltipText,
                  className: "win__footer-block",
                  style: {
                    backgroundColor: "transparent",
                    color: "initial",
                    display: "inline-flex",
                    margin: "0",
                    minHeight: "initial"
                  }
                }), React.createElement("div", {
                  className: "win__footer-block",
                  style: {
                    position: "absolute",
                    right: "0"
                  }
                }, React.createElement("p", undefined, React.createElement("a", {
                          href: Utils$RistSchach.github_url
                        }, "Quellcode ", React.createElement(ReactFeather.ExternalLink, {})), " ist unter der ", React.createElement("a", {
                          href: Utils$RistSchach.license_url
                        }, "Mozilla Public License 2.0 ", React.createElement(ReactFeather.ExternalLink, {})), " verfügbar.")));
}

function noDraggy(e) {
  e.preventDefault();
}

function PageTourney$Sidebar(Props) {
  var tournament = Props.tournament;
  var windowDispatch = Props.windowDispatch;
  var tourney = tournament.tourney;
  var roundList = tourney.roundList;
  var setTourney = tournament.setTourney;
  var playersDispatch = tournament.playersDispatch;
  var players = tournament.players;
  var isItOver = tournament.isItOver;
  var activePlayers = tournament.activePlayers;
  var newRound = function ($$event) {
    $$event.preventDefault();
    var confirmed = isItOver && !window.confirm("Alle Runden sind abgeschlossen. Bist du sicher, dass du eine Neue beginnen möchtest?") ? false : true;
    if (confirmed) {
      return Curry._1(setTourney, {
                  id: tourney.id,
                  name: tourney.name,
                  date: tourney.date,
                  playerIds: tourney.playerIds,
                  scoreAdjustments: tourney.scoreAdjustments,
                  byeQueue: tourney.byeQueue,
                  tieBreaks: tourney.tieBreaks,
                  roundList: Data_Rounds$RistSchach.addRound(roundList)
                });
    }
    
  };
  var delLastRound = function ($$event) {
    $$event.preventDefault();
    if (!window.confirm("Bist du dir sicher, dass du die letzte Runde löschen möchtest?")) {
      return ;
    }
    RescriptReactRouter.push("#/tourneys/" + Data_Id$RistSchach.toString(tourney.id));
    var lastRoundId = Data_Rounds$RistSchach.getLastKey(roundList);
    var round = Data_Rounds$RistSchach.get(roundList, lastRoundId);
    if (round !== undefined) {
      Belt_Array.forEach(Data_Rounds$RistSchach.Round.toArray(Caml_option.valFromOption(round)), (function (match_) {
              if (!(match_.result !== /* NotSet */6 && !Data_Match$RistSchach.isBye(match_))) {
                return ;
              }
              var reset = function (id, rating) {
                var player = Belt_Map.get(players, id);
                if (player !== undefined) {
                  return Curry._1(playersDispatch, {
                              TAG: /* Set */1,
                              _0: player.id,
                              _1: Data_Player$RistSchach.predMatchCount(Data_Player$RistSchach.setRating(player, rating))
                            });
                }
                
              };
              reset(match_.whiteId, match_.whiteOrigRating);
              reset(match_.blackId, match_.blackOrigRating);
            }));
    }
    Curry._1(setTourney, {
          id: tourney.id,
          name: tourney.name,
          date: tourney.date,
          playerIds: tourney.playerIds,
          scoreAdjustments: tourney.scoreAdjustments,
          byeQueue: tourney.byeQueue,
          tieBreaks: tourney.tieBreaks,
          roundList: Data_Rounds$RistSchach.delLastRound(roundList)
        });
    if (Data_Rounds$RistSchach.size(roundList) === 0) {
      return Curry._1(setTourney, {
                  id: tourney.id,
                  name: tourney.name,
                  date: tourney.date,
                  playerIds: tourney.playerIds,
                  scoreAdjustments: tourney.scoreAdjustments,
                  byeQueue: tourney.byeQueue,
                  tieBreaks: tourney.tieBreaks,
                  roundList: Data_Rounds$RistSchach.addRound(roundList)
                });
    }
    
  };
  return React.createElement("div", undefined, React.createElement("nav", undefined, React.createElement("ul", {
                      style: {
                        marginTop: "0"
                      }
                    }, React.createElement("li", undefined, React.createElement(Router$RistSchach.Link.make, {
                              children: null,
                              to_: /* TournamentList */1,
                              onDragStart: noDraggy,
                              onClick: (function (param) {
                                  Curry._1(windowDispatch, {
                                        TAG: /* SetSidebar */1,
                                        _0: false
                                      });
                                })
                            }, React.createElement(ReactFeather.ChevronLeft, {}), React.createElement("span", {
                                  className: "sidebar__hide-on-close"
                                }, " Zurück")))), React.createElement("hr", undefined), React.createElement("ul", undefined, React.createElement("li", undefined, React.createElement(Router$RistSchach.Link.make, {
                              children: null,
                              to_: {
                                TAG: /* Tournament */0,
                                _0: tourney.id,
                                _1: /* Setup */3
                              },
                              onDragStart: noDraggy,
                              onClick: (function (param) {
                                  Curry._1(windowDispatch, {
                                        TAG: /* SetSidebar */1,
                                        _0: false
                                      });
                                })
                            }, React.createElement(ReactFeather.Settings, {}), React.createElement("span", {
                                  className: "sidebar__hide-on-close"
                                }, " Setup"))), React.createElement("li", undefined, React.createElement(Router$RistSchach.Link.make, {
                              children: null,
                              to_: {
                                TAG: /* Tournament */0,
                                _0: tourney.id,
                                _1: /* Players */0
                              },
                              onDragStart: noDraggy,
                              onClick: (function (param) {
                                  Curry._1(windowDispatch, {
                                        TAG: /* SetSidebar */1,
                                        _0: false
                                      });
                                })
                            }, React.createElement(ReactFeather.Users, {}), React.createElement("span", {
                                  className: "sidebar__hide-on-close"
                                }, " Spieler"))), React.createElement("li", undefined, React.createElement(Router$RistSchach.Link.make, {
                              children: null,
                              to_: {
                                TAG: /* Tournament */0,
                                _0: tourney.id,
                                _1: /* Status */4
                              },
                              onDragStart: noDraggy,
                              onClick: (function (param) {
                                  Curry._1(windowDispatch, {
                                        TAG: /* SetSidebar */1,
                                        _0: false
                                      });
                                })
                            }, React.createElement(ReactFeather.Activity, {}), React.createElement("span", {
                                  className: "sidebar__hide-on-close"
                                }, " Status"))), React.createElement("li", undefined, React.createElement(Router$RistSchach.Link.make, {
                              children: null,
                              to_: {
                                TAG: /* Tournament */0,
                                _0: tourney.id,
                                _1: /* Crosstable */2
                              },
                              onDragStart: noDraggy,
                              onClick: (function (param) {
                                  Curry._1(windowDispatch, {
                                        TAG: /* SetSidebar */1,
                                        _0: false
                                      });
                                })
                            }, React.createElement(ReactFeather.Layers, {}), React.createElement("span", {
                                  className: "sidebar__hide-on-close"
                                }, " Crosstable"))), React.createElement("li", undefined, React.createElement(Router$RistSchach.Link.make, {
                              children: null,
                              to_: {
                                TAG: /* Tournament */0,
                                _0: tourney.id,
                                _1: /* Scores */1
                              },
                              onDragStart: noDraggy,
                              onClick: (function (param) {
                                  Curry._1(windowDispatch, {
                                        TAG: /* SetSidebar */1,
                                        _0: false
                                      });
                                })
                            }, React.createElement(ReactFeather.List, {}), React.createElement("span", {
                                  className: "sidebar__hide-on-close"
                                }, " Punktestand")))), React.createElement("hr", undefined), React.createElement("h5", {
                      className: "sidebar__hide-on-close sidebar__header"
                    }, "Runden"), React.createElement("ul", {
                      className: "center-on-close"
                    }, Belt_Array.mapWithIndex(Data_Rounds$RistSchach.toArray(roundList), (function (id, param) {
                            return React.createElement("li", {
                                        key: String(id)
                                      }, React.createElement(Router$RistSchach.Link.make, {
                                            children: null,
                                            to_: {
                                              TAG: /* Tournament */0,
                                              _0: tourney.id,
                                              _1: /* Round */{
                                                _0: id
                                              }
                                            },
                                            onDragStart: noDraggy,
                                            onClick: (function (param) {
                                                Curry._1(windowDispatch, {
                                                      TAG: /* SetSidebar */1,
                                                      _0: false
                                                    });
                                              })
                                          }, id + 1 | 0, Data_Rounds$RistSchach.isRoundComplete(roundList, activePlayers, id) ? React.createElement("span", {
                                                  className: "sidebar__hide-on-close caption-20"
                                                }, " Abgeschlossen ", React.createElement(ReactFeather.Check, {})) : React.createElement("span", {
                                                  className: "sidebar__hide-on-close caption-20"
                                                }, " Steht aus ", React.createElement(ReactFeather.AlertTriangle, {}))));
                          })))), React.createElement("hr", undefined), React.createElement("ul", undefined, React.createElement("li", undefined, React.createElement("button", {
                          className: "sidebar-button",
                          style: {
                            width: "100%"
                          },
                          disabled: !tournament.isNewRoundReady,
                          onClick: newRound
                        }, React.createElement(ReactFeather.Plus, {}), React.createElement("span", {
                              className: "sidebar__hide-on-close"
                            }, " Neue Runde"))), React.createElement("li", {
                      style: {
                        textAlign: "center"
                      }
                    }, React.createElement("button", {
                          className: "button-micro sidebar-button",
                          style: {
                            height: "fit-content",
                            marginTop: "8px"
                          },
                          disabled: Data_Rounds$RistSchach.size(roundList) === 0,
                          onClick: delLastRound
                        }, React.createElement(ReactFeather.Trash2, {}), React.createElement("span", {
                              className: "sidebar__hide-on-close"
                            }, " Letzte Runde löschen")))));
}

function PageTourney(Props) {
  var tourneyId = Props.tourneyId;
  var subPage = Props.subPage;
  var windowDispatch = Props.windowDispatch;
  return React.createElement(LoadTournament$RistSchach.make, {
              children: (function (tournament) {
                  var tmp;
                  if (typeof subPage === "number") {
                    switch (subPage) {
                      case /* Players */0 :
                          tmp = React.createElement(PageTourneyPlayers$RistSchach.make, {
                                tournament: tournament
                              });
                          break;
                      case /* Scores */1 :
                          tmp = React.createElement(PageTourneyScores$RistSchach.make, {
                                tournament: tournament
                              });
                          break;
                      case /* Crosstable */2 :
                          tmp = React.createElement(PageTourneyScores$RistSchach.Crosstable.make, {
                                tournament: tournament
                              });
                          break;
                      case /* Setup */3 :
                          tmp = React.createElement(PageTourneySetup$RistSchach.make, {
                                tournament: tournament
                              });
                          break;
                      case /* Status */4 :
                          tmp = React.createElement(PageTournamentStatus$RistSchach.make, {
                                tournament: tournament
                              });
                          break;
                      
                    }
                  } else {
                    tmp = React.createElement(PageRound$RistSchach.make, {
                          roundId: subPage._0,
                          tournament: tournament
                        });
                  }
                  return React.createElement(Window$RistSchach.Body.make, {
                              children: tmp,
                              windowDispatch: windowDispatch,
                              footerFunc: (function (param) {
                                  return React.createElement(PageTourney$Footer, {
                                              tournament: tournament
                                            });
                                }),
                              sidebarFunc: (function (param) {
                                  return React.createElement(PageTourney$Sidebar, {
                                              tournament: tournament,
                                              windowDispatch: param
                                            });
                                })
                            });
                }),
              tourneyId: tourneyId,
              windowDispatch: windowDispatch
            });
}

var make = PageTourney;

export {
  make ,
}
/* react Not a pure module */
