// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Data_Player$RistSchach from "./Data_Player.bs.js";
import * as Data_Scoring$RistSchach from "./Data_Scoring.bs.js";

function getExpected(a, b) {
  return 1 / (1 + Math.pow(10, (b - a | 0) / 400));
}

function updateRating(rating, expected, actual, current) {
  return Math.round(current + rating * (actual - expected)) | 0;
}

function getKFactor(matchCount, rating) {
  if (Data_Player$RistSchach.NatInt.toInt(matchCount) < 30) {
    return 40;
  } else if (rating > 2100) {
    return 10;
  } else {
    return 20;
  }
}

function keepAboveFloor(rating) {
  if (rating > 100) {
    return rating;
  } else {
    return 100;
  }
}

function calcNewRatings(whiteRating, blackRating, whiteMatchCount, blackMatchCount, result) {
  var whiteElo = getKFactor(whiteMatchCount, whiteRating);
  var blackElo = getKFactor(blackMatchCount, blackRating);
  var whiteExpected = getExpected(whiteRating, blackRating);
  var blackExpected = getExpected(blackRating, whiteRating);
  var whiteResult = Data_Scoring$RistSchach.Score.toFloat(Data_Scoring$RistSchach.Score.fromResultWhite(result));
  var blackResult = Data_Scoring$RistSchach.Score.toFloat(Data_Scoring$RistSchach.Score.fromResultBlack(result));
  return [
          keepAboveFloor(updateRating(whiteElo, whiteExpected, whiteResult, whiteRating)),
          keepAboveFloor(updateRating(blackElo, blackExpected, blackResult, blackRating))
        ];
}

var EloRank = {
  getKFactor: getKFactor
};

export {
  EloRank ,
  calcNewRatings ,
}
/* Data_Player-RistSchach Not a pure module */
