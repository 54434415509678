// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import Numeral from "numeral";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Data_Id$RistSchach from "../Data/Data_Id.bs.js";
import * as Data_Player$RistSchach from "../Data/Data_Player.bs.js";
import * as Data_Rounds$RistSchach from "../Data/Data_Rounds.bs.js";
import * as Data_Scoring$RistSchach from "../Data/Data_Scoring.bs.js";

function useRoundData(roundId, param) {
  var match = param.tourney;
  var roundList = match.roundList;
  var scoreAdjustments = match.scoreAdjustments;
  var scoreData = React.useMemo((function () {
          return Data_Scoring$RistSchach.fromTournament(roundList, scoreAdjustments);
        }), [
        roundList,
        scoreAdjustments
      ]);
  var isThisTheLastRound = roundId === Data_Rounds$RistSchach.getLastKey(roundList);
  var match$1 = Data_Rounds$RistSchach.get(roundList, roundId);
  var unmatched;
  if (match$1 !== undefined && isThisTheLastRound) {
    var matched = Data_Rounds$RistSchach.Round.getMatched(Caml_option.valFromOption(match$1));
    unmatched = Belt_Map.removeMany(param.activePlayers, matched);
  } else {
    unmatched = Belt_Map.make(Data_Id$RistSchach.id);
  }
  var unmatchedWithDummy = Belt_Map.set(unmatched, Data_Id$RistSchach.dummy, Data_Player$RistSchach.dummy);
  return {
          scoreData: scoreData,
          unmatched: unmatched,
          unmatchedWithDummy: unmatchedWithDummy
        };
}

function getScoreInfo(player, scoreData, avoidPairs, getPlayer, players, origRating, newRating) {
  var data = Belt_Map.get(scoreData, player.id);
  var match = data !== undefined ? data : Data_Scoring$RistSchach.make(player.id);
  var opponentResults = match.opponentResults;
  var hasBye = Belt_List.some(opponentResults, (function (param) {
          return Data_Id$RistSchach.isDummy(param[0]);
        }));
  var x = Curry._1(Data_Scoring$RistSchach.Score.Sum.toFloat, Data_Scoring$RistSchach.Score.sum(match.colorScores));
  var colorBalance = x < 0.0 ? "Weiß +" + String(Math.abs(x)) : (
      x > 0.0 ? "Scharz +" + String(x) : "Neutral"
    );
  var opponentResults$1 = Belt_Array.mapWithIndex(Belt_List.toArray(opponentResults), (function (i, param) {
          var result = param[1];
          var opId = param[0];
          var tmp;
          if (result !== 0) {
            switch (result) {
              case /* One */1 :
                  tmp = "Gewonnen";
                  break;
              case /* NegOne */2 :
                  tmp = "Verloren";
                  break;
              case /* Half */3 :
                  tmp = "Unentschieden";
                  break;
              
            }
          } else {
            tmp = "Verloren";
          }
          return React.createElement("li", {
                      key: Data_Id$RistSchach.toString(opId) + ("-" + String(i))
                    }, Data_Player$RistSchach.fullName(Curry._1(getPlayer, opId)), " - ", tmp);
        }));
  var avoidListHtml = Belt_Array.map(Belt_Option.mapWithDefault(Belt_Map.get(Curry._1(Data_Id$RistSchach.Pair.$$Set.toMap, avoidPairs), player.id), [], Belt_Set.toArray), (function (pId) {
          var p = Belt_Map.get(players, pId);
          if (p !== undefined) {
            return React.createElement("li", {
                        key: Data_Id$RistSchach.toString(pId)
                      }, Data_Player$RistSchach.fullName(p));
          } else {
            return null;
          }
        }));
  var rating = React.createElement(React.Fragment, undefined, origRating, newRating !== undefined ? React.createElement("span", undefined, " (", Numeral(newRating - origRating | 0).format("+0"), ")") : null);
  return {
          player: player,
          hasBye: hasBye,
          colorBalance: colorBalance,
          score: Curry._1(Data_Scoring$RistSchach.Score.Sum.toFloat, Data_Scoring$RistSchach.Score.calcScore(match.results, match.adjustment)),
          rating: rating,
          opponentResults: opponentResults$1,
          avoidListHtml: avoidListHtml
        };
}

export {
  useRoundData ,
  getScoreInfo ,
}
/* react Not a pure module */
