// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as ReactDom from "react-dom";
import * as Localforage from "localforage";
import * as Pages$RistSchach from "./Pages.bs.js";
import * as Router$RistSchach from "./Router.bs.js";
import * as Window$RistSchach from "./Window.bs.js";
import * as LocalforageGetitems from "localforage-getitems";
import * as LocalforageSetitems from "localforage-setitems";
import * as PageOptions$RistSchach from "./PageOptions.bs.js";
import * as PagePlayers$RistSchach from "./PagePlayers.bs.js";
import * as PageTourney$RistSchach from "./PageTournament/PageTourney.bs.js";
import * as LocalforageRemoveitems from "localforage-removeitems";
import * as PageTournamentList$RistSchach from "./PageTournamentList.bs.js";

function App$App(Props) {
  var url = Router$RistSchach.useUrl(undefined);
  return React.createElement(Window$RistSchach.make, {
              children: (function (windowDispatch) {
                  var tmp;
                  if (typeof url === "number") {
                    switch (url) {
                      case /* Index */0 :
                          tmp = React.createElement(Window$RistSchach.Body.make, {
                                children: React.createElement(Pages$RistSchach.Splash.make, {}),
                                windowDispatch: windowDispatch
                              });
                          break;
                      case /* TournamentList */1 :
                          tmp = React.createElement(PageTournamentList$RistSchach.make, {
                                windowDispatch: windowDispatch
                              });
                          break;
                      case /* PlayerList */2 :
                          tmp = React.createElement(PagePlayers$RistSchach.make, {
                                windowDispatch: windowDispatch
                              });
                          break;
                      case /* TimeCalculator */3 :
                          tmp = React.createElement(Window$RistSchach.Body.make, {
                                children: React.createElement(Pages$RistSchach.TimeCalculator.make, {}),
                                windowDispatch: windowDispatch
                              });
                          break;
                      case /* Options */4 :
                          tmp = React.createElement(PageOptions$RistSchach.make, {
                                windowDispatch: windowDispatch
                              });
                          break;
                      case /* NotFound */5 :
                          tmp = React.createElement(Window$RistSchach.Body.make, {
                                children: React.createElement(Pages$RistSchach.NotFound.make, {}),
                                windowDispatch: windowDispatch
                              });
                          break;
                      
                    }
                  } else {
                    tmp = url.TAG === /* Tournament */0 ? React.createElement(PageTourney$RistSchach.make, {
                            tourneyId: url._0,
                            subPage: url._1,
                            windowDispatch: windowDispatch
                          }) : React.createElement(PagePlayers$RistSchach.make, {
                            id: url._0,
                            windowDispatch: windowDispatch
                          });
                  }
                  return React.createElement("main", {
                              className: "app__main"
                            }, tmp);
                }),
              className: "app"
            });
}

var App = {
  make: App$App
};

function init(param) {
  var root = document.querySelector("#root");
  if (root == null) {
    console.error("Couldn't find root.");
  } else {
    ReactDom.render(React.createElement(App$App, {}), root);
  }
}

LocalforageGetitems.extendPrototype(Localforage);

LocalforageRemoveitems.extendPrototype(Localforage);

LocalforageSetitems.extendPrototype(Localforage);

export {
  App ,
  init ,
}
/*  Not a pure module */
